import React from "react";
import { Container } from "@material-ui/core";
import { TableComponent } from "../components/TableComponent";

export const ListingPage = () => {
  return (
    <div>
      <h1>MPK kurssilistaus</h1>
      <Container>
        <TableComponent />
      </Container>
    </div>
  );
};
