import React, { useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { useQuery } from "react-query";
import { getCourses } from "../api/api";
import { CourseDescriptionComponent } from "./CourseDescriptionComponent";
import { Dialog } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";

export const TableComponent = () => {
  const [courseId, setCourseId] = useState<string>();
  const [open, setOpen] = useState<boolean>(false);
  const { data } = useQuery("courses", getCourses);

  const columns = [
    // { field: "id", headerName: "Id", visibility: "none" },
    { field: "Ajankohta", headerName: "Ajankohta", width: 200 },
    { field: "PostitoimipaikkaListassa", headerName: "Paikka", width: 130 },
    { field: "Nimi", headerName: "Nimi", width: 500 },
    { field: "TapahtumaID", headerName: "Lisätiedot", width: 200 },
  ];

  const rows = data?.map((item, idx) => ({
    id: idx,
    ...item,
  }));

  return (
    <div style={{ height: "92vh", width: "100%" }}>
      <DataGrid
        rows={rows ?? []}
        columns={columns}
        pageSize={20}
        onRowClick={(params: any) => {
          setCourseId(params.row.TapahtumaID);
          setOpen(true);
        }}
      />
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setCourseId(undefined);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Fade in={open}>
          <div className="modal-container">
            {courseId && <CourseDescriptionComponent courseId={courseId} />}
          </div>
        </Fade>
      </Dialog>
    </div>
  );
};
