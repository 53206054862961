import axios from "axios";
import { TWO_HOURS } from "../constants";
import { Course } from "../types";
import { retrieveCourseData, storeCourseData } from "../utils/localstorage";

export const getCourses = (): Promise<Course[]> => {
  const cachedData = retrieveCourseData();

  if (!cachedData || cachedData.timestamp < Date.now() - TWO_HOURS) {
    return axios
      .get(process.env.REACT_APP_BACKEND_URL!!, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        const data = response.data as Course[];
        storeCourseData(data);
        return data;
      });
  } else {
    return cachedData.data;
  }
};

export const getCourseDescription = (courseId: string): Promise<string> =>
  axios
    .get(`${process.env.REACT_APP_BACKEND_URL!!}description/${courseId}`, {
      headers: {
        Accept: "application/json",
      },
    })
    .then((response) => response.data as string);
