import { Course, CourseData } from "../types";

const COURSE_DATA_KEY = "course-data";

export const storeCourseData = (courseData: Course[]) => {
  console.log(`Caching courses...`);
  localStorage.setItem(
    COURSE_DATA_KEY,
    JSON.stringify({
      timestamp: Date.now(),
      data: courseData,
    })
  );
};

export const retrieveCourseData = (): CourseData | undefined => {
  console.log(`Retrieving cached courses...`);
  const data = localStorage.getItem(COURSE_DATA_KEY);
  return data != null ? JSON.parse(data) : undefined;
};
